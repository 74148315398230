/*--------------------------------------------------------------------/
	feed
/--------------------------------------------------------------------*/
.feed-box {
	&__wrap {
		/*overflow: hidden;*/
		text-align: left;
		color: $white;
		@include media(pc_s) {
			margin: 0 auto;
			position: relative;
		}
	}
	&__table {
		width: 100%;
		&__wrap {
			max-height: 300px;
			overflow-y: auto;
		}
		& th,& td {
			display: block;
			text-align: left;
			@include f-em(14);
			@include media(pc_s) {
				display: table-cell;
			}
		}
		& th {
			@include f-w;
			padding: .3rem .7rem 0 0;
			@include media(pc_s) {
				width: 120px;
			}
		}
		& td {
			padding: 0 .7rem .7rem 0;
			@include media(pc_s) {
				padding-bottom: 2rem;
			}
			a {
				color: $white;
				@include transition;
			}
		}
	}
}

/* blog-category */
[class^="feed__icon"] {
	display: inline-block;
	margin-left: 1.5rem;
	padding: .5rem .6rem;
	line-height: 1.2;
	background-color: $d-gray;
}